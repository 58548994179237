<template>
  <div id="product" v-if="isLogin">
    <v-flex xs12 v-if="data">
      <v-layout wrap class="product mt-8">
        <v-flex xs12 sm12 md6>
          <ProductWeb v-if="!isMobile && data" :data="data" @countdown='data.countdown = false' :playindicator="playindicator"/>
          <ProductMobile v-if="isMobile && data" 
            :data="data" 
            :isSuccess="success" 
            :updatedIsWishlist="updatedIsWishlist" 
            :playindicator="playindicator"
            @submit="setMySize($event)" 
            @share="share()" 
            @openRemind="openRemind($event)"
            @fullscreen="openSlider($event)"
            @addToCartBundling="addToCartBundling($event)" />
        </v-flex>
        <v-flex xs12 sm12 md6 v-if="data && !isMobile">
          <div class="product-detail">
            <h1> {{ data.product_name }}</h1>

            <div style="float:right">
              <template v-if="!data.is_bundling">
                <v-btn v-if="updatedIsWishlist || data.is_wishlist" icon color="red">
                  <v-icon medium>mdi-heart</v-icon>
                </v-btn>

                <v-btn v-else icon color="black" @click="openRemind('Add to Wishlist')">
                  <v-icon medium>mdi-heart-outline</v-icon>
                </v-btn>
              </template>
            </div>

            <p class="text"> 
              <span class="text-harga"> 
                {{ addComma(data.selling_price) }} IDR
              </span>
              <span class="text-c" v-if="data.harga_coret && data.harga_coret !== '0'"> / {{ addComma(data.harga_coret) }} IDR </span>
            </p>
            <p>
             <span class="text-sold mb-0" v-if="data.sold_qty && data.sold_qty !== '0' && brand === '9'"> {{ data.sold_qty}} pcs terjual</span>
            </p>
            <!-- <p class="text"> {{ data.product_code }} </p> -->
            <div class="text-d" v-html="data.description"></div>

            <!-- Not Bundling Package -->
            <div v-if="!data.is_bundling">
              <table class="bordered mt-9" v-if="showSizeList && (data && !data.countdown) && data.is_coming_soon === 'NO'">
                <tr v-for="(item,index) in data.variant" :key="index" @click="chooseSize(item)">
                  <td>{{ item.ukuran }}</td>
                </tr>
              </table>
              <table class="bordered mt-9 " @click="showSizeList = !showSizeList" v-if="!showSizeList && (data && !data.countdown) && data.is_coming_soon === 'NO'">
                <tr class="choosed">
                  <td>{{ mySize.ukuran }}</td>
                </tr>
              </table>
              <p class="text2" v-if="data && (!data.countdown || data.is_coming_soon) === 'NO'">{{ $t('shop.pick-variant') }}</p>
              <v-btn v-if="data.status === 'sold'" class="mt-5" tile color="black" width="100%" height="44px" @click="openRemind('Remind Me')">
                <span class="btn-text"> REMIND ME </span>
              </v-btn>
              <v-btn v-else class="mt-5" tile color="black" width="100%" height="44px" @click="checkIsCountDown()" :disabled="data.status === 'sold' || data.is_coming_soon !== 'NO'">
                <span class="btn-text" v-if="data.status === 'sold' || data.is_coming_soon !== 'NO'"> 
                  <b v-if="data.status === 'sold'"> {{ $t('shop.empty-product') }}</b>
                  <b v-if="data.is_coming_soon !== 'NO'"> COMING SOON</b> 
                </span>
                <span class="btn-text" v-else> {{ $t('shop.add-to-cart') }} </span>
              </v-btn>
            </div>
            <!-- End of Not Bundling Package -->

            <!-- Bundling Package -->
            <div v-else>
              <v-btn v-if="data.status === 'sold'" class="mt-5" tile color="black" width="100%" height="44px" :disabled="true">
                <span class="btn-text"> SOLD </span>
              </v-btn>
              <div v-else>
                <BundlingLg :data="data" :success="successAddBundling" @addtocart="addToCartBundling($event)" />
              </div>
            </div>
          </div>
        </v-flex>
        <!-- <v-flex xs12 class="mt-9 mb-16" v-if="!isMobile && data && data.related_product" style="padding: 0 5%">
          <h3> {{ data.judul_related }} </h3>
          <ProductSlider :data="data.related_product"/>
        </v-flex> -->
      </v-layout>
    </v-flex>

    <v-dialog v-model="remindDialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="false"
    >
      <RemindMe v-if="remindDialog" :variants="data.variant_existing" :title="subtitle" @submit="remindMe($event)"/>
    </v-dialog>

    <v-dialog v-model="sliderDialog"
      :fullscreen="true"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="false"
    >
      <FullScreenSlider v-if="sliderDialog" :data="sliderImages" :options="sliderOptions" @close="sliderDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import ProductMobile from '@/components/ProductMobile3'
  import ProductWeb from '@/components/ProductWeb3'
  import RemindMe from '@/components/RemindMe'
  import FullScreenSlider from '@/components/FullScreenSlider'
  import BundlingLg from '@/components/Bundling/BundlingLg'

  export default {
    name: 'Product',
    components: { ProductMobile, ProductWeb, RemindMe, FullScreenSlider, BundlingLg},
    computed: {
      ...mapState([
        'isMobile',
        'cart',
        'cartNumber',
        'category',
        'token',
        'bgColor',
        'isLogin'
      ])
    },
    data () {
      return {
        brand: process.env.VUE_APP_BRAND,
        data: null,
        mySize: null,
        success: false,
        showSizeList: true,
        dialog: false,
        remindDialog: false,
        coversionResponse: '',
        subtitle: '',
        updatedIsWishlist: false,
        sliderDialog: false,
        sliderOptions: null,
        successAddBundling: false,
        playindicator: [],
        sliderImages: []
      }
    },
    methods: {
      openRemind (subtitle) {
        this.subtitle = subtitle
        if (this.token) {
          this.remindDialog = true
        } else {
          this.$store.commit('setLoginDialog', true)
        }
      },
      createPlayerIndicator () {
        if (this.data.url_media) {
          this.playindicator = this.data.url_media.map(item => {
            return {
              type: item.type,
              play: false
            }
          })
        }
      },
      share () {
        this.dialog = true
      },
      getProduct () {
        this.$store.commit('setIsLoading', true)

        if (this.token) {
          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            product_code: this.$route.params.id,
            token: this.token,
            is_offline: true
          }
        } else {
          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            product_code: this.$route.params.id,
            is_offline: true
          }
        }

        axios.get('/api/v1/pages/product')
          .then(response => {
            this.data = response.data.data
            this.setMetaInfo(this.data, null)

            this.createPlayerIndicator()
          })
          .catch(error => {
            this.data = null
            this.message = error.response.data.api_msg
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', this.message)

            if (error.response && error.response.status === 404) { 
              setTimeout(() => {
                this.$router.push({'name': 'Home'})
              },5000)
            }
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      chooseSize (item) {
        this.mySize = item
        this.showSizeList = false
      },
      getOldQuantity (item) {
        let data = this.cart.find(val => val.size.sku_product === item.size.sku_product)
        if (data) {
          return Number(data.quantity)
        } else {
          return 0
        }
      },
      setMySize (item) {
        this.mySize = item
        this.submit()
      },
      checkIsCountDown () {
        if (this.data && this.data.countdown) {
          this.$store.commit('setSnackbar', true)
          this.$store.commit('setText', 'Kamu tidak dapat melanjutkan pesanan, barang ini masih dalam perhitungan mundur (countdown)')
        } else {
          this.submit()
        }
      },
      submit() {
        this.success = false
        if (this.mySize) {
          let data = {
            data: this.data,
            size: this.mySize,
            quantity: 1,
            url: process.env.VUE_APP_BASE_URL + this.$route.path,
            valid: true,
            isBundling: this.data.is_bundling,
            bundling: null
          }

          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            is_offline: true
          }

          axios.post('/api/v1/orders/add_to_cart', {
              sku_product: data.size.sku_product,
              qty: data.quantity,
              old_qty: this.getOldQuantity(data),
              total_cart_qty: this.cartNumber
          })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              this.$store.commit('addToCart', data)
              this.mySize = null
              this.showSizeList = true
              this.success = true
            })
            .catch(error => {
              this.success = false
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        } else {
          this.$store.commit('setSnackbar', true)
          this.$store.commit('setText', 'Anda belum memilih Size')
        }
      },
      remindMe(item) {
        let data = {
          sku_product: item.variant,
          qty: item.qty,
          message: item.message
        }

        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios.post('/api/v1/member/wishlist', data)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', response.data.message)

            this.updatedIsWishlist = true
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.remindDialog = false
            this.$store.commit('setIsLoading', false)
          })
      },
      openSlider ($event) {
        this.sliderDialog = true
        this.sliderImages = this.data.url_media.filter(item => item.type !== 'video')
        let index = this.sliderImages.findIndex(item => item.url === $event.url)
        this.sliderOptions =  {
          currentPage: index ? index : 0,
          direction: 'horizontal',
          pagination: false,
          loop:true
        }
      },
      addToCartBundling ($event) {
        this.successAddBundling = false
        let data = {
          data: $event.data,
          size: {},
          quantity: 1,
          url: process.env.VUE_APP_BASE_URL + this.$route.path,
          valid: true,
          isBundling: $event.data.is_bundling,
          bundling: $event.bundling
        }

        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Berhasil add to cart')

        setTimeout(() => {
          this.successAddBundling = true
        })

        this.$store.commit('addToCartBundling', data)
      }
    },
    mounted () {
       setTimeout(() => {
        if (!this.isLogin) {
          this.$router.push({ name: 'Home'})
        } else {
          this.getProduct()
        }
      }, 100)


      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', this.$t('shop.product-detail'))
    }
  }
</script>

<style scoped>   
  .product-detail {
    color: #000000;
    margin: 10%;
  }

  .text {
    font-size: 1.3rem;
  }

  .text-harga {
    color: #da291c;
  }

  .text-sold {
    color: #000 !important;
  }

  .text2 {
    font-size: 0.8rem;
  }

  .text-c {
    font-size: 0.8rem;
    color: #000000;
    text-decoration: line-through;
  }

  .text-d >>> p {
    font-family: Helvetica;
    font-size: 0.8rem;
    margin: 0;
  }

  .bordered {
    font-family: Helvetica;
    margin: 10px 0;
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .bordered tr {
    margin-bottom: 10px;
    cursor: pointer;
  }

  .bordered tr:hover {
    font-weight: bolder;
    background-color: #C0C0C0;
  }

  .bordered td {
    padding: 10px 5px; 
  }

  .choosed {
    background-color: #C0C0C0;
  }

  .btn-text {
    color: #FFFFFF;
    text-transform: uppercase;
  }

  .text-d >>> img {
    width: 85%;
    padding: 12px 0;
  }
</style>
